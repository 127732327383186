<template>
  <div class="page-user-choserole">
    <div class="my-page">
      <div class="top">
        <p>选择登录用户</p>
        <div>请根据您的个人信息，在以下选项中选择一个角色登录</div>
      </div>
      <div class="role-box">
        <div
          class="role1"
          :class="{ active: roleType === 2 }"
          @click="roleType = 2"
        >
          <div class="left">
            <p>我是老师</p>
            <div>勤于施教，安于乐教</div>
          </div>
          <div class="right"></div>
        </div>
        <div
          class="role2"
          :class="{ active: roleType === 3 }"
          @click="roleType = 3"
        >
          <div class="left">
            <p>我是家长</p>
            <div>良师益友，陪伴成长</div>
          </div>
          <div class="right"></div>
        </div>
      </div>
      <van-button type="primary" block color="#47AFA7" @click="next()"
        >确认</van-button
      >
    </div>
  </div>
</template>

<script>
import { Button, Dialog } from "vant";
export default {
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog
  },
  data() {
    return {
      roleType: 2,
      changeRoleInfo: JSON.parse(window.localStorage.getItem("wxuserInfo"))
    };
  },
  mounted() {
    // this.getUser()
  },
  methods: {
    next() {
      // 判断用户选择
      if (this.roleType == 3) {
        window.localStorage.setItem("Principal", false);
        this._getAuditStateInfo();
      } else {
        this.chooseTeacher();
      }
    },
    // 获取状态信息
    _getAuditStateInfo() {
      window.localStorage.setItem("IsAdministration", false);
      this.$axios
        .post("/api/WeChat/IdentityLogin", {
          OpenID: this.changeRoleInfo.OpenID,
          UserType: 3,
          headUrl: this.changeRoleInfo.headUrl
        })
        .then(res => {
          // 非初次注册
          if (res.data.IsLocalUser) {
            let userInfo = res.data.loginUser;
            // 存储Token
            window.localStorage.setItem("TOKEN", userInfo.Token);
            window.localStorage.setItem("UserType", userInfo.UserType);
            if (res.data.HasNoChildren) {
              // 存储用户信息
              window.localStorage.setItem("userInfo", JSON.stringify(userInfo));
              Dialog.alert({
                title: "",
                message: "请先切换宝贝或添加宝贝",
                confirmButtonText: "确定",
                showCancelButton: true,
                confirmButtonColor: "rgb(253, 229, 0)"
              }).then(() => {
                this.$router.push({
                  path: "/ParentMy"
                });
              });
            }
            // 弹窗显示未通过信息
            else if (res.data.AuditState == 4) {
              Dialog.alert({
                title: "",
                message: res.msg + "(" + res.data.AuditDesc + ")",
                confirmButtonText: "编辑",
                showCancelButton: true,
                confirmButtonColor: "rgb(253, 229, 0)"
              }).then(() => {
                // 审核中
                console.log("5");
                // 跳转到注册编辑页面
                this.$router.push({
                  path: "/RegisterEdit",
                  query: {
                    ID: res.data.ID
                  }
                });
              });
            } else if (res.data.AuditState == 2 || res.data.AuditState == 1) {
              Dialog.alert({
                title: "",
                message: res.msg,
                confirmButtonText: "关闭",
                confirmButtonColor: "rgb(253, 229, 0)"
              }).then(() => {
                // on close
              });
            } else {
              //审核通过
              let userInfo = res.data.loginUser;
              // 存储用户信息
              // 存储Token
              window.localStorage.setItem("TOKEN", userInfo.Token);
              // 存储UserType 1 学生，2职工，3家长
              window.localStorage.setItem("UserType", userInfo.UserType);
              // 存储Token
              window.localStorage.setItem("SchoolID", userInfo.ReturnSchoolID);
              window.localStorage.setItem("loginUserID", userInfo.ID);
              // 存储用户信息
              window.localStorage.setItem("userInfo", JSON.stringify(userInfo));
              if (
                window.localStorage.getItem("jumpUrl") == null ||
                window.localStorage.getItem("jumpUrl") == ""
              ) {
                this.$router.replace("Home");
              } else {
                const url = window.localStorage.getItem("jumpUrl");
                window.localStorage.removeItem("jumpUrl");
                this.$router.replace(url);
              }
            }
          } else {
            // 初次注册
            this.$router.push("/ParentRegister");
          }
        });
    },
    chooseTeacher() {
      this.$axios
        .post("/api/WeChat/IdentityLogin", {
          OpenID: this.changeRoleInfo.OpenID,
          UserType: this.roleType,
          headUrl: this.changeRoleInfo.headUrl
        })
        .then(res => {
          if (res.code === 200) {
            // 需要得到的用户信息存起来，注册的时候会用到，未注册的调往注册
            const data = res.data;
            if (data.IsLocalUser) {
              window.localStorage.setItem(
                "IsAdministration",
                data.IsAdministration
              );
              // 是否为院长
              window.localStorage.setItem("Principal", data.Principal);
              window.localStorage.setItem(
                "userInfo",
                JSON.stringify(data.loginUser)
              );
              // 存储UserType 1 学生，2职工，3家长
              window.localStorage.setItem("UserType", data.loginUser.UserType);
              window.localStorage.setItem("TOKEN", data.loginUser.Token);
              window.localStorage.setItem(
                "SchoolID",
                data.loginUser.ReturnSchoolID
              );
              window.localStorage.setItem("loginUserID", data.loginUser.ID);
              if (
                window.localStorage.getItem("jumpUrl") == null ||
                window.localStorage.getItem("jumpUrl") == ""
              ) {
                this.$router.replace("Home");
              } else {
                const url = window.localStorage.getItem("jumpUrl");
                window.localStorage.removeItem("jumpUrl");
                this.$router.replace(url);
              }
            } else {
              const obj = {
                OpenID: data.OpenID,
                headUrl: data.headUrl,
                userName: data.userName
              };
              window.localStorage.setItem("wxuserInfo", JSON.stringify(obj));
              this.$router.push({
                path: "/Login",
                query: {
                  roleType: this.roleType
                }
              });
              // window.location.href = 'http://tjb.whsedu.cn/#/RegisterRole'
            }
          } else {
            window.localStorage.clear();
            // Toast.fail(res.data || res.msg || '请求失败')
          }
        });
    }
  }
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
